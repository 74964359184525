.contentContainer {
  border-radius: 30px;
}

.confirmButton:hover,
.cancelButton:hover {
  opacity: 0.4;
  box-shadow: none !important;
}

.confirmButton:active {
  background: #e6e6e0 !important;
  color: #ffffff !important;
  opacity: 1;
  border-color: #e6e6e0 !important;
}

.cancelButton:active {
  color: #4a4a4a !important;
  opacity: 1;
}

.modal-title img {
  display: flex;
  margin: auto;
}

.modal-header .close {
  padding: 1rem 1rem 1rem 1rem;
}
