
:root {
  --grayish-blue: #c8c8cd;
  --primary-color: #ffcc00;
  --strong-blue-twenty: #0572ce20;
  --dark-grayish-blue: #282c34;
  --soft-cyan: #61dafb;
}

*,
::after,
::before {
  box-sizing: content-box !important;
}

*:focus {
  outline: none !important;
}

p {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.loading-bar {
  display: flex;
  height: 5px;
  width: 100%;
  max-width: 320px;
  box-shadow: inset 0px 0px 0px 1px var(--grayish-blue);
  border-radius: 50px;
  overflow: hidden;
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 100%;
}
.progress-bar-value-stop {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.progress-bar {
  height: 4px;
  background-color: var(--strong-blue-twenty);
  width: 100%;
  overflow: hidden;
}
@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }

  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: var(--dark-grayish-blue);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--soft-cyan);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
