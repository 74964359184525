:root {
  --light-grey-transcript-2: #333333;
  --very_light_pink_three: #d8d8d8;
  --marigold: #ffcc00;
  --white: #ffffff;
}

.reportContentContainer {
  border-top-width: 0px;
  border-bottom-width: 0px;
  min-height: 100%;
  height: auto;
  max-height: 100%;
  overflow-y: scroll;
  border-radius: 0px;
  padding: 0 84px;
  border: 0;
  scrollbar-width: none;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  scrollbar-width: none;
}

.reportContentContainer::-webkit-scrollbar {
  display: none;
}

.tab-content {
  margin-bottom: 100px;
}

.nav-tabs {
  margin-bottom: 45px;
}

label {
  font-size: 16px;
  color: var(--light-grey-transcript-2);
  display: flex;
}

input[type='radio'] {
  margin-right: 34px;
  margin-top: 3px;
}

.reportErrorForm {
  text-align: start;
  margin-left: 25px;
}

.nav-tabs {
  border: 1px solid var(--very_light_pink_three);
  display: flex;
  border-radius: 0;
}

.nav-tabs .nav-item {
  flex: 0.5;
  border-right: 1px solid var(--very_light_pink_three);
  border-radius: 0;
  margin-top: -1px;
  padding-top: 1px;
  cursor: pointer;
}

.nav-tabs .nav-link {
  padding: 4px 1rem;
}

.nav-tabs .nav-link.active {
  color: var(--white);
  background: var(--marigold);
  border: none;
  border-radius: 0;
  padding-top: 5px;
}

.nav-tabs .nav-link.active:hover {
  color: var(--white);
  border: none;
  border-color: none;
}

.radio {
  margin-bottom: 14px;
}

.reportContentContainer div h5 {
  font-size: 24px;
  font-weight: bold;
  padding: 37px 35px 0px;
  text-align: center;
}

.reportModalBackdrop.show {
  background: var(--white);
  opacity: 0.9;
}

.reportModalClose {
  position: fixed;
  opacity: 1;
  padding: 7px 0px !important;
  margin-left: 16px !important;
  margin-bottom: 10px !important;
  right: 3.5%;
}
