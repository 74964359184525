ol.carousel-indicators li,
ol.carousel-indicators li.active {
  width: 8px;
  height: 8px;
  border: none;
  border-radius: 50%;
  background: #ffffff;
  opacity: 1;
}

ol.carousel-indicators li.active {
  background: #f5a623;
}
